<template>
  <div class="o-dashboard">
    <div class="row no-gutters h-100">
      <div class="col-10 offset-1 col-lg-12 offset-lg-0">
        <div
          class="row no-gutters align-items-center h-100 justify-content-center"
        >
         <!--  <div class="col-lg-5">
            <app-last-activity></app-last-activity>
          </div> -->
          <div class="col-12 p-5">
            <b-alert variant="success" show class="text-center" v-if="this.downloadLogs == 'true'">
            <p>Scarica i log dal {{this.startdate }} al {{this.enddate }}</p>
            <b-button variant="primary"  class="ml-2" v-on:click="exportLogs"
              >Download</b-button>
            </b-alert>
          </div>
          <div class="col-lg-5">
            <app-last-activity-insert></app-last-activity-insert>
          </div>
          <div class="col-lg-5 offset-lg-1">
            <app-last-contacts></app-last-contacts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import AppLastActivity from "@/components/AppLastActivity.vue"; */
import AppLastContacts from "@/components/AppLastContacts.vue";
import AppLastActivityInsert from '../components/AppLastActivityInsert.vue';
import apiAxios from "../libs/apiAxios";
import moment from "moment";
export default {
  components: { AppLastContacts, AppLastActivityInsert },

  name: "Dashboard",

  data() {
    return {
      usersStructurePayload: [],
      resources: null,
      rolesRights: null,
      downloadLogs: localStorage.downloadLogs,

      startdate : moment().startOf('month').subtract(1, 'month').format('DD/MM/YYYY'),
      enddate : moment().format('DD/MM/YYYY'),
    };
  },

  created() {
    this.usersStructureCheck();
  },

  methods: {
    usersStructureCheck() {
      if( this.downloadLogs != 'true'){
      this.usersStructurePayload = this.$store.getters["usersStructure"];
      this.resources = this.usersStructurePayload.usersStructure.resources;
      this.rolesRights = this.usersStructurePayload.usersStructure.rolesRights;
      const resourcesLogs = this.resources.find(resources => resources.code === "AppLogs_ExportAsSpreadsheet");
      const rolesLogs = this.rolesRights.find(rolesRights => rolesRights.id === resourcesLogs.id);
      this.downloadLogs = `${rolesLogs.read}`;
      localStorage.setItem('downloadLogs',rolesLogs.read);
      } else {
        this.downloadLogs = localStorage.downloadLogs;
      }
    },

    async exportLogs() {
      const fromDt = moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD HH:mm:ss');
      console.log(fromDt);
       const toDt = moment().format('YYYY-MM-DD') + ' 23:59:59';
      console.log(toDt);
      await apiAxios
          .get(
            `api/commons/applogs/spreadsheet?fromDt=${fromDt}&toDt=${toDt}`,
            {
              responseType: "blob",
            }
          )
          .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", "export_logs.xlsx");
            document.body.appendChild(fileLink);

            
            fileLink.click();
          })
          .catch((error) => console.log(error)); 

    },
  }
};

  </script>
