import apiAxios from '../../../libs/apiAxios'
export default {
    async getActivity({ commit,state }) {
        console.log(state);
        await apiAxios.get(`api/foppacrm/activities`)
        .then(response => {
            console.log(response.data);
        commit('SET_ACTIVITY', response.data)
        })
        .catch((error) => console.log(error));
    },

    async getActivityPage({ commit,state }, data) {
        console.log(data);
        console.log('getActivityPage');
        if(data != ""){
            await apiAxios.get(`api/foppacrm/activities?nrItemsPerPage=${state.perPage}&nrCurrentPage=${data}`)
            .then(response => {
            commit('SET_ACTIVITY', response.data)
            })
            .catch((error) => console.log(error));
        } else {
            await apiAxios.get(`api/foppacrm/activities?nrItemsPerPage=${state.perPage}&nrCurrentPage=1`)
            .then(response => {
            commit('SET_ACTIVITY', response.data)
            })
            .catch((error) => console.log(error));
        }
       
    },
    

    async putActivty(context, data){
        const ActivitiesId = data.id;
  
        if(process.env.VUE_APP_DEBUG=="true"){
            console.log(data);
            console.log(ActivitiesId);
        }
        await apiAxios.put(`/api/foppacrm/activities/${ActivitiesId}`, 
            { 
                "idActivityType": parseInt(data.activityType_Id),
                "code": String(data.code),
                "name": data.name,
                "description": data.description,
                "sorting": parseInt(data.sorting),
                "startDt": data.startDt,
                "endDt": data.endDt
            }, 
            
          
        ).then(response => {
            if(process.env.VUE_APP_DEBUG=="true"){
                console.log(response.data);
            }
            if(response.data.isSuccess == true){
                context.commit('EDIT_ACTIVITY', {
                   ...response.data.payload,
                });
            } else {
                context.commit('SET_ERROR_ACTIVITY', {
                   ...response.data,
                   call: "PUT activity",
                   date: new Date()
                });
            }
            })
            .catch((error) => console.log(error)); 
    },

    async postActivty(context, data){
        await apiAxios.post(`/api/foppacrm/activities`, 
            { 
                "idActivityType": parseInt(data.activityType_Code),
                "code": String(data.code),
                "name":  data.name,
                "sorting": 0,
                "startDt": data.startDt,
                "endDt": data.endDt,
            }, 
        
            ).then(response => {
                console.log(response.data);
                if(response.data.isSuccess == true){
                    context.commit('ADD_ACTIVITY', {
                       ...response.data.payload,
                    });
                } else {
                    context.commit('SET_ERROR_ACTIVITY', {
                       ...response.data,
                       call: "POST activitytypes",
                       date: new Date()
                    });
                }
                })
            .catch((error) => console.log(error));
    },

    async deleteActivty(context, data){
        const ActivitiesId = parseInt(data);
        await apiAxios.delete(`/api/foppacrm/activities/${ActivitiesId}` 
           
        ).then(response => {
            if(process.env.VUE_APP_DEBUG=="true"){
                console.log(response.data);
            }
            if(response.data.isSuccess == true){
                context.commit('DELETE_ACTIVITY', {
                   ...response.data.payload,
                });
            } else {
                context.commit('SET_ERROR_ACTIVITY', {
                   ...response.data,
                   call: "PUT activitytypes",
                   date: new Date()
                });
            }
            })
            .catch((error) => console.log(error));

    },

    async getActivitiesChildren(context, data){
        const ActivitiesId = data.id;
        await apiAxios.get(`/api/foppacrm/activities/${ActivitiesId}/children`
          
        ).then(response => {
            if(process.env.VUE_APP_DEBUG=="true"){
                console.log(response.data);
            }
            if(response.data.isSuccess == true){
                context.commit('ADD_ACTIVITY_CHILDREN', response.data)
            } else {
                context.commit('SET_ERROR_ACTIVITY', {
                   ...response.data,
                   call: "GET getActivitiesChildren",
                   date: new Date()
                });
            }
            })
            .catch((error) => console.log(error)); 
    },

    async getLastActivity({ commit }) {
        await apiAxios.get(`api/foppacrm/activities/latest?maxItems=6`)
        .then(response => {
            commit('ADD_LASTACTIVITY', response.data.payload);
            commit('SET_ACTIVITY', response.data)
        })
        .catch((error) => console.log(error));
    },
    
};