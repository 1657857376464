<template>
  <div class="login">
    <app-login></app-login>
  </div>
</template>

<script>

import AppLogin from '@/components/AppLogin.vue'

export default {
  name: 'Login',
  components: {
    AppLogin
  }
}
</script>
