import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import activity from './modules/Activitys/index.js'
import contact from './modules/Contacts/index.js'
import courses from './modules/Courses/index.js'
import knownustypes from './modules/KnownUsTypes/index.js'
import typeActivity from './modules/TypeActivity/index.js'
import contactsImports from './modules/ContactsImports/index.js'
import VCalendar from 'v-calendar';


Vue.use(VCalendar, {
  componentPrefix: 'v'
});
Vue.use(Vuex)


console.log(process.env.VUE_APP_API_ENDPOINT);
export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {

  },
  getters: {

  },
  modules: {
    user,
    activity: activity,
    contact: contact,
    typeActivity: typeActivity,
    courses: courses,
    contactsImports: contactsImports,
    KnownusTypes: knownustypes
  }
})




