import apiAxios from '../../../libs/apiAxios'
export default {
  async getContactsByActivitiesPage({ state, commit }, data) {
    const $activitiesId = parseInt(data.id);
    const $perPage = parseInt(state.perPage);
    const $nrCurrentPage = parseInt(data.nrCurrentPage) + 1;
    const $search = data.search;
    if ($search == "" || $search == null) {
      await apiAxios.get(`api/foppacrm/Activities/${$activitiesId}/contacts?nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
        .then(response => {
          commit('SET_CONTACT', response.data)
        })
        .catch((error) => console.log(error));
    } else {
      await apiAxios.get(`api/foppacrm/Activities/${$activitiesId}/contacts?searchText=${$search}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
        .then(response => {
          commit('SET_CONTACT', response.data)
        })
        .catch((error) => console.log(error));
    }

  },

  /* async getContactsPage({ state, commit }, data) { */
  async getContactsPage({ commit }, data) {

    /* const $perPage = parseInt(state.perPage); */
    const $perPage = parseInt(data.perPage);
    const $nrCurrentPage = parseInt(data.nrCurrentPage) + 1;

    let $searchText = null;
    if (data.searchText === "") {
      $searchText = null;
    } else {
      $searchText = data.searchText;
    }

    let $courseName = null;
    if (data.courseName === "") {
      $courseName = null;
    } else {
      $courseName = data.courseName;
    }

    let $typeCourse = null;
    if (process.env.VUE_APP_API_ENDPOINT == "https://apicrmits.bizonweb.it/") {
      if ($courseName == -1) {
        $typeCourse = "ITS";
      } else if ($courseName == -2) {
        $typeCourse = "IFTS";
      } else if ($courseName == -3) {
        $typeCourse = "UNASSIGNED";
      }
    } else {
      if (data.courseName == -1) {
        $typeCourse = "BIENNIAL";
      } else if (data.courseName == -2) {
        $typeCourse = "TRIENNIAL";
      } else if ($courseName == -3) {
        $typeCourse = "UNASSIGNED";
      }
    }

    let $isNewsletter = null;
    if (data.isNewsletter === "") {
      $isNewsletter = null;
    } else {
      $isNewsletter = data.isNewsletter;
    }

    const $filter = data.filter;


    
      /* console.log(data);
      console.log($perPage);
      console.log($nrCurrentPage); 
      console.log($searchText);
      console.log('courseName' ,$courseName);
      console.log($typeCourse); */
    


    let complexFilters = "";
    $filter.forEach(function (item) {
      let strig = "";
      if (item.startEventDt === null && item.endEventDt === null && item.note === null) {
        /*  console.log(item.idActivityType);
         console.log(item.idActivity);
         console.log(item.condition);
         console.log(item.bondType);
         console.log(item.eventDt);
         console.log(item.note); */
        console.log('### SCELTA 1');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};;;`;
      } else if (item.startEventDt !== null && item.endEventDt !== null && item.note === null) {
        /*  console.log(item.idActivityType);
         console.log(item.idActivity);
         console.log(item.condition);
         console.log(item.bondType);
         console.log(item.eventDt);
         console.log(item.note); */
        console.log('### SCELTA 2');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};${item.startEventDt};${item.endEventDt};`;
      } else if (item.startEventDt === null && item.endEventDt === null && item.note !== null) {
        /* console.log(item.idActivityType);
        console.log(item.idActivity);
        console.log(item.condition);
        console.log(item.bondType);
        console.log(item.eventDt);
        console.log(item.note); */
        console.log('### SCELTA 3');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};;;${item.note}`;
      } else {
        /* console.log(item.idActivityType);
        console.log(item.idActivity);
        console.log(item.condition);
        console.log(item.bondType);
        console.log(item.eventDt);
        console.log(item.note); */
        console.log('### SCELTA 7');
        strig = `${complexFilters}&complexFilters[]=${item.idActivityType};${item.idActivity};${item.condition};${item.bondType};${item.startEventDt};${item.endEventDt};${item.note}`;
      }
      complexFilters = strig;
    });
    /*  console.log('$isNewsletter');
     console.log($isNewsletter); */
    if ($isNewsletter !== null) {
      if ($searchText !== null && $courseName !== null && complexFilters !== "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}&idCourses=${$courseName}${$string_typeCourse}${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 1');

      } else if ($searchText !== null && $courseName !== null && complexFilters === "") {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}&idCourses=${$courseName}${$string_typeCourse}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 2');

      } else if ($searchText === null && $courseName !== null && complexFilters === "") {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        console.log($string_typeCourse);
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}&isNewsletter=${$isNewsletter}${$string_typeCourse}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 3');

      } else if ($searchText !== null && $courseName === null && complexFilters === "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */

        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&isNewsletter=${$isNewsletter}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 4');

      } else if ($searchText === null && $courseName !== null && complexFilters !== "") {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&isNewsletter=${$isNewsletter}&idCourses=${$courseName}${$string_typeCourse}${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
        console.log('### CALL 5');

      } else if ($searchText !== null && $courseName === null && complexFilters !== "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&isNewsletter=${$isNewsletter}&searchText=${$searchText}${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
        console.log('### CALL 6');

      } else if ($searchText === null && $courseName === null && complexFilters !== "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true${complexFilters}&isNewsletter=${$isNewsletter}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 7');

      } else {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&nrItemsPerPage=${$perPage}&isNewsletter=${$isNewsletter}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 8');

      }
    } else {
      if ($searchText !== null && $courseName !== null && complexFilters !== "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityEventDt&includeArchived=true&searchText=${$searchText}&idCourses=${$courseName}${$string_typeCourse}${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 1');

      } else if ($searchText !== null && $courseName !== null && complexFilters === "") {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&idCourses=${$courseName}${$string_typeCourse}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 2');

      } else if ($searchText === null && $courseName !== null && complexFilters === "") {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        console.log($string_typeCourse);
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}${$string_typeCourse}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 3');

      } else if ($searchText !== null && $courseName === null && complexFilters === "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */

        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 4');

      } else if ($searchText === null && $courseName !== null && complexFilters !== "") {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        let $string_typeCourse = "";
        if ($typeCourse != null) {
          $string_typeCourse = `&typeCourse=${$typeCourse}`;
          $courseName = 0;
        }
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&idCourses=${$courseName}${$string_typeCourse}${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
        console.log('### CALL 5');

      } else if ($searchText !== null && $courseName === null && complexFilters !== "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&searchText=${$searchText}${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
        console.log('### CALL 6');

      } else if ($searchText === null && $courseName === null && complexFilters !== "") {
        /*  console.log($searchText);
         console.log($courseName);
         console.log(complexFilters); */
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true${complexFilters}&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 7');

      } else {
        /* console.log($searchText);
        console.log($courseName);
        console.log(complexFilters); */
        await apiAxios.get(`api/foppacrm/Contacts?sortingDirs[]=desc&sortingFields[]=lastActivityId&includeArchived=true&nrItemsPerPage=${$perPage}&nrCurrentPage=${$nrCurrentPage}`)
          .then(response => {
            commit('SET_CONTACT', response.data)
          })
          .catch((error) => console.log(error));
        console.log('### CALL 8');

      }
    }






  },

  async postContact(context, data) {
    let user = {
      "joinWith": [
        "all"
      ],
      "notes": data.notes,
      "isPrivacy": data.isPrivacy,
      "isNewsletter": data.isNewsletter,
      "person": {
        "id": 0,
        "firstName": data.firstName,
        "lastName": data.lastName,
        "email": data.email,
        "birthDt": data.birthDt,
        "phoneNumber": data.phoneNumber,
      },
    }

    if (data.activityType_Code != '' && data.contactsCourses != '') {
      user = {
        "joinWith": [
          "all"
        ],
        "notes": data.notes,
        "isPrivacy": data.isPrivacy,
        "isNewsletter": data.isNewsletter,
        "person": {
          "id": 0,
          "firstName": data.firstName,
          "lastName": data.lastName,
          "email": data.email,
          "birthDt": data.birthDt,
          "phoneNumber": data.phoneNumber,
        },
        "contactsCourses": [
          {
            "idCourse": parseInt(data.contactsCourses),
          }
        ],
        "contactsActivities": [
          {
            "idActivity": data.activity_Code,
            "note": "",
            "eventDt": data.eventDt,
          }
        ]
      }
    } else if (data.activityType_Code != '' && data.contactsCourses == '') {
      user = {
        "joinWith": [
          "all"
        ],
        "notes": data.notes,
        "isPrivacy": data.isPrivacy,
        "isNewsletter": data.isNewsletter,
        "person": {
          "id": 0,
          "firstName": data.firstName,
          "lastName": data.lastName,
          "email": data.email,
          "birthDt": data.birthDt,
          "phoneNumber": data.phoneNumber,
        },
        "contactsActivities": [
          {
            "idActivity": data.activity_Code,
            "note": "",
            "eventDt": data.eventDt,
          }
        ]
      }
    } else if (data.activityType_Code == '' && data.contactsCourses != '') {
      user = {
        "joinWith": [
          "all"
        ],
        "notes": data.notes,
        "isPrivacy": data.isPrivacy,
        "isNewsletter": data.isNewsletter,
        "person": {
          "id": 0,
          "firstName": data.firstName,
          "lastName": data.lastName,
          "email": data.email,
          "birthDt": data.birthDt,
          "phoneNumber": data.phoneNumber,
        },
        "contactsCourses": [
          {
            "idCourse": parseInt(data.contactsCourses),
          }
        ],
      }
    } else if (data.activityType_Code == '' && data.contactsCourses == '') {
      user = {
        "joinWith": [
          "all"
        ],
        "notes": data.notes,
        "isPrivacy": data.isPrivacy,
        "isNewsletter": data.isNewsletter,
        "person": {
          "id": 0,
          "firstName": data.firstName,
          "lastName": data.lastName,
          "email": data.email,
          "birthDt": data.birthDt,
          "phoneNumber": data.phoneNumber,
        },
      }
    }


    console.log(user);

    await apiAxios.post(`/api/foppacrm/contacts`,
      user,

    ).then(response => {
      console.log(response.data);
      if (response.data.isSuccess == true) {
        context.commit('ADD_CONTACT_NEW', {
          ...response.data.payload,
        });
      } else {
        context.commit('SET_ERROR_ACTIVITY', {
          ...response.data,
          call: "POST activitytypes",
          date: new Date()
        });
      }
    })
      .catch((error) => console.log(error));


  },

  async archiveSingleContacts(context, data) {
    console.log(data);
    const ContactsId = parseInt(data.idContact);
    console.log(ContactsId);
    await apiAxios.put(`/api/foppacrm/Contacts/${ContactsId}/archive`, {
      "archived": true,
    })
      .then(response => {
        if (process.env.VUE_APP_DEBUG == "true") {
          /* console.log(response.data); */
        }
        if (response.data.isSuccess == true) {
          context.commit('DELETE_CONTACT', {
            ...response.data.payload,
          });
        } else {
          context.commit('SET_ERROR_CONTACT', {
            ...response.data,
            call: "delete Contacts",
            date: new Date()
          });
        }
      })
      .catch((error) => console.log(error));

  },


  async archiveGroupContacts(context, data) {
    console.log(data);
    /* const ContactsId = parseInt(data.idContact);
    console.log(ContactsId); */
    await apiAxios.put(`/api/foppacrm/contacts/archive`, {
      "ids": data,
      "archived": true,
    })
      .then(response => {
        if (process.env.VUE_APP_DEBUG == "true") {
          console.log(response.data);
        }
        if (response.data.isSuccess == true) {
          /*   context.commit('DELETE_CONTACT', {
               ...response.data.payload,
            }); */
        } else {
          context.commit('SET_ERROR_CONTACT', {
            ...response.data,
            call: "delete Contacts",
            date: new Date()
          });
        }
      })
      .catch((error) => console.log(error));

  },


  async deleteContacts(context, data) {
    console.log(data);
    const ContactsId = parseInt(data.idContact);
    console.log(ContactsId);
    await apiAxios.delete(`/api/foppacrm/Contacts/${ContactsId}`)
      .then(response => {
        if (process.env.VUE_APP_DEBUG == "true") {
          /* console.log(response.data); */
        }
        if (response.data.isSuccess == true) {
          context.commit('DELETE_CONTACT', {
            ...response.data.payload,
          });
        } else {
          context.commit('SET_ERROR_CONTACT', {
            ...response.data,
            call: "delete Contacts",
            date: new Date()
          });
        }
      })
      .catch((error) => console.log(error));

  },

  async postAddActitivityToContact(context, data) {
    console.log('postAddActitivityToContact');
    console.log(data);
    const Activities = {
      "includeArchived": data.includeArchived_activity,
      "contactsActivities": []
    }

    data.contactUpdateActivity.forEach(function (contact) {
      Activities.contactsActivities.push({ ...contact });
    });
    console.log(Activities);
    apiAxios.post(`/api/foppacrm/Contacts/activities`,
      Activities,

    ).then(response => {
      console.log(response.data);
      if (response.data.isSuccess == true) {
        console.log(response.data.isSuccess);
      } else {
        context.commit('SET_ERROR_ACTIVITY', {
          ...response.data,
          call: "POST postAddActitivityToContact",
          date: new Date()
        });
      }
    })
      .catch((error) => console.log(error));

  },

  async putActitivityToContact(context, data) {
    console.log(data);

    const Activities = {
      "includeArchived": true,
      "contactsActivities": []
    }

    Activities.contactsActivities.push({ ...data });

    apiAxios.post(`/api/foppacrm/contacts/activities`,
      Activities,

    ).then(response => {
      console.log(response.data);
      if (response.data.isSuccess == true) {
        console.log(response.data.isSuccess);
      } else {
        context.commit('SET_ERROR_ACTIVITY', {
          ...response.data,
          call: "POST postAddActitivityToContact",
          date: new Date()
        });
      }
    })
      .catch((error) => console.log(error));

  },

  async getSingleContact({ commit }, data) {
    const $id = data.id
    await apiAxios.get(`api/foppacrm/Contacts/${$id}`)
      .then(response => {
        commit('ADD_SINGLECONTACT', response.data)
      })
      .catch((error) => console.log(error));
  },

  async deleteContactsActivity(context, data) {
    console.log(data);
    const idContact = parseInt(data.idContact);
    const idContactActivity = parseInt(data.activity);
    console.log(idContact);
    console.log(idContactActivity);
    await apiAxios.delete(`/api/foppacrm/contacts/${idContact}/activities/${idContactActivity}`)
      .then(response => {
        if (process.env.VUE_APP_DEBUG == "true") {
          console.log(response.data);
        }
        if (response.data.isSuccess == true) {
          /*     context.commit('DELETE_CONTACT', {
                ...response.data.payload,
              }); */
        } else {
          context.commit('SET_ERROR_CONTACT', {
            ...response.data,
            call: "delete Contacts",
            date: new Date()
          });
        }
      })
      .catch((error) => console.log(error));

  },


  async putContact(context, data) {
    const ContactId = data.id;

    if (process.env.VUE_APP_DEBUG == "true") {
      console.log(data);
      console.log(ContactId);
    }

    const user = {
      "joinWith": [
        "all"
      ],
      "notes": data.editContact.notes,
      "isPrivacy": data.editContact.isPrivacy,
      "isNewsletter": data.editContact.isNewsletter,
      "idKnownUsType": data.editContact.editKnown,
      "knownUsTypeDesc": data.editContact.knownUsTypeDesc,
      "person": data.editPerson,
      "contactsCourses": data.editCourses,
      "contactsActivities": data.contactsActivities,
    }

    console.log(user);
    return await apiAxios.put(`/api/foppacrm/contacts/${ContactId}`,
      user,

    ).then(response => {
      if (process.env.VUE_APP_DEBUG == "true") {
        console.log(response.data);
      }
      if (response.data.isSuccess == true) {
        context.commit('EDIT_CONTACT', {
          ...response.data.payload,
        });
      } else {
        context.commit('SET_ERROR_CONTACT', {
          ...response.data,
          call: "PUT CONTACT",
          date: new Date()
        });
      }

      return response.data.isSuccess;
    })
      .catch((error) => console.log(error));
  },

  async getLastContact({ commit }) {
    await apiAxios.get(`/api/foppacrm/contacts/latest?maxItems=15&sortingFields[]=lastActivityId&sortingDirs[]=desc`)
      .then(response => {
        if (process.env.VUE_APP_DEBUG == "true") {
          console.log('getLastContact');
          console.log(response.data.payload.contacts);
        }
        if (response.data.isSuccess == true) {
          commit('ADD_LASTCONTACT', response.data.payload);
          commit('SET_CONTACT', response.data);
        }
      })
      .catch((error) => console.log(error));
  },

  async getLastContactInsert({ commit }) {
    await apiAxios.get(`/api/foppacrm/contacts/latest?maxItems=15&sortingFields[]=createdDt&sortingDirs[]=desc`)
      .then(response => {
        if (process.env.VUE_APP_DEBUG == "true") {
          console.log('getLastContact');
          console.log(response.data.payload.contacts);
        }
        if (response.data.isSuccess == true) {
          commit('ADD_LASTCONTACTINSERT', response.data.payload);
          commit('SET_CONTACT', response.data);
        }
      })
      .catch((error) => console.log(error));
  },
};