import axios from 'axios'
import store from '@/store'



/* authToken = store.getters.token; */
const apiAxios = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

/* apiAxios.interceptors.request.use(

  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

let isRefreshing = false;
apiAxios.interceptors.response.use(function (response) {
  console.log('buon fine chiamata')
  console.log(response);

  return response;
}, function (error) {

  const {
    response: { status }
  } = error
  console.log('401 ---');


  if (status === 40199999) {
    if (!isRefreshing) {
      isRefreshing = true;
      store.dispatch('refreshToken');
      isRefreshing = false;
    }
  }
  return Promise.reject(error);
});
 */
apiAxios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
apiAxios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await store.dispatch('refreshToken');
    return apiAxios(originalRequest);
  }
  return Promise.reject(error);
});

export default apiAxios
